import { useRoutes } from "react-router-dom";

import routes from "./routes/index";
// import Routes from "./Routes";
import { Footer, Header } from "./shared-components";
import { Helmet } from "react-helmet";
// import Routes from "./Routes";
import ScrollToTop from "./shared-components/scroll-to-top";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'


// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    },
  },
})


function App() {
  const routeResult = useRoutes(routes);

  return (
    <>
      <Helmet>
        <base href="/"></base>
        <meta name="theme-color" content="#000000" data-react-helmet="true" />
        <meta name="description" content="Freshment is India&#x27;s leading Job Portal - Search &amp; Apply for Latest Job Vacancies across Top Companies in India, Employers can Post Job &amp; Hire Candidates for Free" data-react-helmet="true" />
        <meta name="title" content="Search Jobs Online | Hire Candidates | Post a Job for Free | Freshment | freshmentapp.com" data-react-helmet="true" />
        <meta name="image" data-react-helmet="true" content="https://freshmentapp.com/static/media/freshmentLogo.a5200e9f.svg" />


        <link rel="canonical" href="https://freshmentapp.com" data-react-helmet="true" />
        <link rel="alternate" href="https://freshmentapp.com" hrefLang="en-in" data-react-helmet="true" />


        <meta name="author" content="freshment" />
        <meta property="business:contact_data:locality" content="Rajkot" data-react-helmet="true" />
        <meta property="business:contact_data:postal_code" content="360002" data-react-helmet="true" />
        <meta property="business:contact_data:country_name" content="India" data-react-helmet="true" />
        <meta property="business:contact_data:email" content="contact@freshmentapp.com" data-react-helmet="true" />
        <meta property="business:contact_data:website" content="https://freshmentapp.com" data-react-helmet="true" />
        <meta itemProp="name" content="Freshment - Find a candidate in 72 hours, Find a job in 7 days" data-react-helmet="true" />
        <meta property="profile:username" content="freshment" data-react-helmet="true" />
        <meta itemProp="description" content="Freshment is India&#x27;s leading Job Portal - Search &amp; Apply for Latest Job Vacancies across Top Companies in India, Employers can Post Job &amp; Hire Candidates for Free" data-react-helmet="true" />
        <meta property="og:title" content="Search Jobs Online | Hire Candidates | Post a Job for Free | Freshment | freshmentapp.com" data-react-helmet="true" />
        <meta property="og:description" content="Freshment is India&#x27;s leading Job Portal - Search &amp; Apply for Latest Job Vacancies across Top Companies in India, Employers can Post Job &amp; Hire Candidates for Free" data-react-helmet="true" />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta data-react-helmet="true" property="og:url" content="https://freshmentapp.com" />
        <meta data-react-helmet="true" property="og:determiner" content="Post Jobs To Get Candidates | Hire Staff Easily In 72 Hours" />
        <meta data-react-helmet="true" name="twitter:title" content="Search Jobs Online | Hire Candidates | Post a Job for Free | Freshment | freshmentapp.com" />
        <meta data-react-helmet="true" name="twitter:description" content="Freshment is India&#x27;s leading Job Portal - Search &amp; Apply for Latest Job Vacancies across Top Companies in India, Employers can Post Job &amp; Hire Candidates for Free" />
        <meta data-react-helmet="true" name="twitter:card" content="summary_large_image" />
        <meta data-react-helmet="true" name="twitter:creator" content="freshment" />
        <meta data-react-helmet="true" name="twitter:image" content="https://freshmentapp.com/static/media/about-office-1.1b443800.png" />
        <meta data-react-helmet="true" name="robots" content="index,follow" />
        <meta data-react-helmet="true" name="google" content="notranslate" />
        <meta data-react-helmet="true" http-equiv="Content-Language" content="en_US" />
        <meta data-react-helmet="true" name="keywords" content="job search, jobs, work, employment, career, resumes, job vacancies, government jobs, careerbuilder, part time jobs, work from home jobs, online jobs, recruitment, data entry jobs " />

      </Helmet>
      <main>
        {/* <Header></Header> */}

        {/* You can use by useRoutes like this (I prefer it): */}
        <Header />
        {/* <div className="min-w-[1440px]"> */}

        {/* Provide the client to your App */}
        <QueryClientProvider client={queryClient}>
          {routeResult}
        </QueryClientProvider>
        {/* </div> */}
        {/* <Routes /> */}
        <Footer />
        {/* Or use by defining it */}
        <ScrollToTop />
      </main>
    </>
  );
}

export default App;
